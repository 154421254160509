<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <form class="needs-validation" @submit.prevent="requiredForm">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="branchId">สาขา:</label>

                        <multiselect
                          id="branchId"
                          v-model="part.branchId"
                          :options="localData"
                          class=""
                          :class="{
                            'is-invalid': submitform && $v.part.branchId.$error,
                          }"
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                          @input="handleSearch"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.part.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.part.branchId.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-md-9 col-sm-9 col-9">
                          <div class="mb-3 position-relative">
                            <label for="roId"> เลือกจากเลขที่ใบแจ้งซ่อม:</label>
                            <!-- disabled="disabled"  -->
                            <multiselect
                              :disabled="
                                this.part.status == 'APV' ||
                                this.part.status == 'SUBMIT'
                              "
                              id="roId"
                              v-model="part.roId"
                              :options="rowRo"
                              style="ts-15"
                              placeholder="ค้นหาเลขที่ใบสั่งซ่อม"
                              label="roCode"
                              :show-labels="false"
                              track-by="roCode"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getRo()"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              :disabled="
                                this.part.status == 'APV' ||
                                this.part.status == 'SUBMIT'
                              "
                              class="btn"
                              variant="info"
                              v-b-modal.modalRo
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="cusId">ชื่อลูกค้า:</label>
                        <!-- disabled="disabled"  -->
                        <multiselect
                          id="cusId"
                          v-model="part.cusId"
                          :options="rowsCus"
                          style="ts-15"
                          placeholder=""
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                          :custom-label="customLabelCus"
                          :loading="loading"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          @search-change="getCustomer()"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="vehicleId">เลขทะเบียน:</label>

                        <multiselect
                          id="vehicleId"
                          :options="rowsVehi"
                          v-model="part.vehicleId"
                          label="licensePlate"
                          :show-labels="false"
                          track-by="licensePlate"
                          :loading="loading"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          @search-change="getDataVehicles()"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="reqDate"> วันที่แจ้งซ่อม:</label>

                        <date-picker
                          v-model="part.reqDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                          :class="{
                            'is-invalid': submitform && $v.part.reqDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.part.reqDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.part.reqDate.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-2 col-sm-3">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="dcPer">ส่วนลด(%):</label>

                        <b-form-input
                          id="dcPer"
                          v-model="reqDcPer"
                          type="number"
                          class="form-control text-end"
                          value="Otto"
                          :class="{
                            'is-invalid': submitform && $v.reqDcPer.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.reqDcPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.reqDcPer.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-2 col-sm-3">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="vatPer"> ภาษีมูลค่าเพิ่ม(%):</label>
                        <!-- disabled="disabled"  -->
                        <b-form-input
                          id="vatPer"
                          v-model="part.vatPer"
                          type="number"
                          class="form-control text-end"
                          value="Otto"
                          :class="{
                            'is-invalid': submitform && $v.part.vatPer.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.part.vatPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.part.vatPer.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="note">หมายเหตุ:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          v-model="part.note"
                          class="form-control"
                          name="textarea"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md">
                      <button
                        class="btn btn-success float-end"
                        type="submit"
                        v-show="SuccessPost"
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-md">
                <button
                  class="btn btn-info float-end"
                  @click="updateReq()"
                  v-show="UpPost"
                >
                  บันทึกการแก้ไขข้อมูล
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>

    <b-modal
      ref="modalRo"
      id="modalRo"
      title="รายการสั่งซ่อม"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="part.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                    @input="handleSearch"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่ใบแจ้งซ่อม:
                  <b-form-input
                    v-model="filter.roCode"
                    type="search"
                    placeholder="เลขที่ใบแจ้งซ่อม"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ชื่อ-นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.customerFullNameTh"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ทะเบียนรถ:
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="ทะเบียนรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-md-3 col-sm-3">
                <div class="mb-4 position-relative">
                  <label class="d-inline align-items-center m-2">
                    วันที่สั่งซ่อม:</label
                  >

                  <date-picker
                    v-model="filter.roDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    @input="handleSearch"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
                <!-- <div class="col-md-3 col-sm-7">
                  <div class="mb-3 position-relative">
                    <label for="veh"
                      >เลือก<code>(เจ้าของรถ/ผู้ขับขี่)</code></label
                    >
                    <multiselect
                      id="veh"
                      v-model="veh"
                      :options="optionfieldVeh"
                      track-by="name"
                      label="name"
                      :show-labels="false"
                      @input="showFieldVeh"
                    ></multiselect>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowRo"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <!-- <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template> -->

              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalRecord === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecord > 0">
              หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
              {{ totalRecord }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    @change="handleChangePage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <!-- <p>
              <b-button size="sm" @click="selectAllRowsVeh"
                >เลือกทั้งหมด</b-button
              >
              |
              <b-button size="sm" @click="clearSelectedVeh"
                >ล้างการเลือก</b-button
              >
            </p> -->
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <!-- end col -->

    <!-- table -->

    <!-- end table -->
  </Layout>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  page: {
    title: appConfig.req,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      isHidden: false,
      enabled: false,
      modalShow: false,
      message: "โปรดระบุข้อมูล",
      title: "เพิ่มใบเบิกอะไหล่",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: appConfig.req,
          href: "/part-requisition",
        },
        {
          text: "เพิ่มใบเบิกอะไหล่",
          active: true,
        },
      ],
      fields: [
        {
          key: "roCode",
          sortable: true,
          label: "เลขที่ใบสั่งซ่อม",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "roDate",
          sortable: true,
          label: "วันที่สั่งซ่อม",
        },

        {
          key: "dcPer",
          sortable: true,
          label: "ส่วนลด",
        },
        {
          key: "vatPer",
          sortable: true,
          label: "ภาษี",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      localData: [],
      selectRowRo: [],
      branchIdRo: "",
      loading: false,
      rowsCus: [],
      rowsVehi: [],
      localDataBranchArray: [],
      svId: this.$route.params.svId,
      localDataBranchUser: [],
      nameState: null,
      selectMode: "single",
      selected: [],
      isLoading: "",
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      rowTest: [],
      totalRows: 1,
      rowParts: [],
      pageOptions: [5, 10, 25, 50, 100],
      filter: {
        roCode: "",
        customerFullNameTh: "",
        licensePlate: "",
        roDate: "",
      },
      rowReqShow: [],
      SuccessPost: true,
      UpPost: false,
      roId: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      rowReqParts: [],
      partsCode: [],
      rowRo: [],
      options: [],
      reqId: "",
      selectedPart: {},
      branchId: "",
      reqTotalPrice: 0, // มูลค่ารวมอะไหล่
      reqDcPer: 0,
      reqDcPrice: 0,
      reqNetPrice: 0,
      reqVatPer: appConfig.vat,
      reqVatPrice: 0,
      reqGrandTotalPrice: 0,
      part: {
        partId: "",
        partCode: "",
        nameTh: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        vatPrice: 0,
        vatPer: appConfig.vat,
        grandTotalPrice: 0, // มูลค่ารวมคิดภาษี
        branchId: "",
        poDate: "",
        vatType: { text: "ภาษีนอก(ยังไม่รวมภาษี)" },
        roId: "",
        cusId: "",
        vehicleId: "",
        reqDate: "",
        note: "",
      },
      searchform: {
        partsCode: "",
      },
      submit: false,
      submitted: false,
      submitform: false,
    };
  },
  validations: {
    part: {
      branchId: {
        required,
      },

      reqDate: {
        required,
      },

      vatPer: {
        required,
      },
      amount: {
        required,
      },
    },

    reqDcPer: { required },

    modelform: {},
  },
  computed: {},

  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.part.branchId = { branchId: item.branchId, nameTh: item.nameTh };
      }
    });
    this.localDataBranchArray = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;

    this.getRo();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    customLabel({ partCode, nameTh }) {
      return `[${partCode != null ? partCode : ""}] - ${
        nameTh != null ? nameTh : ""
      }`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
        if (user.branchId == item.branchId) {
          this.part.branchId = { branchId: item.branchId, nameTh: item.nameTh };
        }
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchArray = localDataBranchId;
      this.getRo(this.branchId);
      this.getParts(this.localDataBranchArray);
      // console.log(this.branchId);
    },
    calculateReq(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(part.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.calTotal();
    },
    calTotal() {
      let reqTotalPrice,
        reqDcPrice,
        reqNetPrice,
        reqVatPrice,
        reqGrandTotalPrice;

      reqTotalPrice = this.rowReqParts.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.reqTotalPrice = reqTotalPrice.toFixed(2);
      reqDcPrice =
        parseFloat(this.reqTotalPrice) * parseFloat(this.reqDcPer / 100);
      if (!isNaN(reqDcPrice)) {
        this.reqDcPrice = reqDcPrice.toFixed(2);
      }
      reqNetPrice =
        parseFloat(this.reqTotalPrice) - parseFloat(this.reqDcPrice);
      if (!isNaN(reqNetPrice)) {
        this.reqNetPrice = reqNetPrice.toFixed(2);
      }

      reqVatPrice = this.reqNetPrice * parseFloat(this.reqVatPer / 100);
      if (!isNaN(reqVatPrice)) {
        this.reqVatPrice = reqVatPrice.toFixed(2);
      }

      reqGrandTotalPrice =
        parseFloat(this.reqNetPrice) + parseFloat(this.reqVatPrice);
      if (!isNaN(reqGrandTotalPrice)) {
        this.reqGrandTotalPrice = reqGrandTotalPrice.toFixed(2);
      }
    },
    modalForm() {
      this.$v.$touch();
      this.submitted = true;
    },
    searchForm() {
      this.$v.$touch();
      this.submit = true;
    },
    showModal(option) {
      this.selectedPart = option;
      this.part.branchId = option;
      this.part.partCode = option.partCode;
      this.part.pricePerOne = option.sellPrice;
      this.part.totalPrice = option.sellPrice;
      this.part.netPrice = option.sellPrice;
      this.part.nameTh = option.nameTh;
      this.part.partId = option.partId;
      this.$refs.modalAmout.show(option);
    },

    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : " "
      }`;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowRo = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      var branch = {
        nameTh: this.selected.branchName,
        branchId: this.selected.branchId,
      };
      var roCode = { roCode: this.selected.roCode, roId: this.selected.roId };
      var customerFullNameTh = {
        nameTh: `${
          this.selected.customerNameTh != null
            ? this.selected.customerNameTh
            : ""
        } ${
          this.selected.customerFamilyNameTh != null
            ? this.selected.customerFamilyNameTh
            : ""
        }`,
        cusId: this.selected.cusId,
      };
      var licensePlate = {
        licensePlate: this.selected.licensePlate,
        vehicleId: this.selected.vehicleId,
      };
      var roDate = this.selected.roDate;
      var dcPer = this.selected.dcPer;
      var vatPer = this.selected.vatPer;

      this.part.branchId = branch;
      this.part.roId = roCode;
      this.part.cusId = customerFullNameTh;
      this.part.vehicleId = licensePlate;
      this.part.reqDate = roDate;
      this.reqDcPer = dcPer;
      this.part.vatPer = vatPer;
      this.$refs["modalRo"].hide();

      // this.getData();
    },

    // eslint-disable-next-line no-unused-vars
    modelForm() {
      this.$v.$touch();
      this.submitted = true;
    },

    requiredForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.part.$invalid != true) {
        this.postReq();
      }
    },
    postReq: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/part-requisition/store", {
          branchId: this.part.branchId.branchId,
          roId: this.part.roId.roId,
          cusId: this.part.cusId.cusId,
          vehicleId: this.part.vehicleId.vehicleId,
          reqDate: this.part.reqDate,
          note: this.part.note,
          vatPer: this.part.vatPer,
          dcPer: this.part.dcPer,
        })
        .then((response) => {
          this.reqId = response.data.reqId;
          this.isHidden = true;
          this.SuccessPost = false;
          this.UpPost = true;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "edit-requisition",
            params: {
              reqId: this.reqId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.getReqShow(this.reqId);
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    postReqPart: function (part) {
      let arr = [];
      this.rowReqParts.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.rowReqParts.push(part);
      }

      this.calculateReq(part);
      this.calTotal();
      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = this.selectedPart.sellPrice;
      part.netPrice;

      // console.log("postPoPart", this.poTotalPrice);
      const formDataPart = new FormData();
      formDataPart.append("reqId", this.reqId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", part.amount);
      formDataPart.append("pricePerOne", part.pricePerOne);
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.dcPrice);
      formDataPart.append("dcPer", part.dcPer);
      formDataPart.append("netPrice", part.netPrice);
      formDataPart.append("vatPer", part.vatPer);
      formDataPart.append("vatPrice", part.vatPrice);
      formDataPart.append("grandTotalPrice", part.grandTotalPrice);
      formDataPart.append("reqTotalPrice", this.reqTotalPrice);
      formDataPart.append("reqDcPer", this.reqDcPer);
      formDataPart.append("reqDcPrice", this.reqDcPrice);
      formDataPart.append("reqNetPrice", this.reqNetPrice);
      formDataPart.append("reqVatPer", this.reqVatPer);
      formDataPart.append("reqVatPrice", this.reqVatPrice);
      formDataPart.append("reqGrandTotalPrice", this.reqGrandTotalPrice);

      useNetw
        .post("api/part-requisition/part/store", formDataPart)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.searchform.partsCode = "";
          this.$refs["modalAmout"].hide();
          this.getReqPart(this.reqId);
          // console.log("postRoWage", this.roId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    updatePart(
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      vatPer,
      vatPrice,
      grandTotalPrice
    ) {
      this.part.partId = partId;
      this.part.pricePerOne = sellPrice;
      this.part.amount = amount;
      this.part.totalPrice = totalPrice;
      this.part.dcPer = dcPer;
      this.part.dcPrice = dcPrice;
      this.part.netPrice = netPrice;
      this.part.vatPer = vatPer;
      this.part.vatPrice = vatPrice;
      this.part.grandTotalPrice = grandTotalPrice;

      useNetw
        .put("api/part-requisition/part/update", {
          reqId: this.reqId,
          partId: this.part.partId,
          amount: this.part.amount,
          pricePerOne: this.part.pricePerOne,
          totalPrice: this.part.totalPrice,
          dcPer: this.part.dcPer,
          dcPrice: this.part.dcPrice,
          netPrice: this.part.netPrice,
          vatPer: this.part.vatPer,
          vatPrice: this.part.vatPrice,
          grandTotalPrice: this.part.grandTotalPrice,
          reqTotalPrice: this.reqTotalPrice,
          reqDcPer: this.reqDcPer,
          reqDcPrice: this.reqDcPrice,
          reqNetPrice: this.reqNetPrice,
          reqVatPer: this.reqVatPer,
          reqVatPrice: this.reqVatPrice,
          reqGrandTotalPrice: this.reqGrandTotalPrice,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getReqPart(this.reqId);
          this.calTotal();
          // this.overlayFlag = false;
        });
    },
    updateReq() {
      useNetw
        .put("api/part-requisition/update", {
          reqId: this.reqId,
          reqDate: this.part.reqDate,
          roId: this.part.roId.roId,
          cusId: this.part.cusId.cusId,
          vehicleId: this.part.vehicleId.vehicleId,
          note: this.part.note,
          totalPrice: this.reqTotalPrice,
          dcPer: this.reqDcPer,
          dcPrice: this.reqDcPrice,
          netPrice: this.reqNetPrice,
          vatPer: this.reqVatPer,
          vatPrice: this.reqVatPrice,
          grandTotalPrice: this.reqGrandTotalPrice,
        })

        .then((response) => {
          this.calTotal();
          this.getReqPart(this.reqId);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // this.$router.push({ name: "purchase-order" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getReqShow: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/part-requisition/show", {
          params: {
            reqId: this.reqId,
          },
        })
        .then((response) => {
          this.rowReqShow = response.data.data;
          this.part.status = response.data.data.status;
          this.part.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };

          this.part.reqDate = response.data.data.reqDate;
          this.part.roId = {
            roId: response.data.data.roId,
            roCode: response.data.data.roCode,
          };
          this.part.cusId = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          };
          this.part.vehicleId = {
            vehicleId: response.data.data.vehicleId,
            licensePlate: response.data.data.licensePlate,
          };
          this.reqDcPer = response.data.data.dcPer;
          this.reqDcPrice = response.data.data.dcPrice;
          this.reqTotalPrice = response.data.data.totalPrice;
          this.reqNetPrice = response.data.data.netPrice;
          this.reqVatPer = response.data.data.vatPer;
          this.reqVatPrice = response.data.data.vatPrice;
          this.reqGrandTotalPrice = response.data.data.grandTotalPrice;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getDataVehicles: function () {
      this.isLoading = true;
      useNetw
        .get("api/part-requisition/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]":
              this.part.branchId !== null
                ? this.part.branchId.branchId
                : this.branchId,
          },
        })
        .then((response) => {
          this.rowsVehi = response.data.data;
          this.part.roId = "";
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getRo: function () {
      this.loading = true;

      useNetw
        .get("api/part-requisition/repair-orders", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.part.branchId !== null
                ? this.part.branchId.branchId
                : this.branchId,
            roCode: this.filter.roCode,
            customerFullNameTh: this.filter.customerFullNameTh,
            licensePlate: this.filter.licensePlate,
            roDate: this.filter.roDate,
          },
        })
        .then((response) => {
          this.rowRo = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
          this.getDataVehicles();
          this.getCustomer();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
          // this.overlayFlag = false;
        });
    },
    getCustomer: function () {
      this.isLoading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]":
              this.part.branchId !== null
                ? this.part.branchId.branchId
                : this.branchId,
          },
        })
        .then((response) => {
          this.rowsCus = response.data.data;
          this.part.roId = "";

          // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getParts: function (branchId) {
      this.localBranchId = branchId;
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-requisition/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: this.localBranchId,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getReqPart: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-requisition/parts", {
          params: {
            reqId: this.reqId,
          },
        })
        .then((response) => {
          this.rowReqParts = response.data.data;
          this.calculateReq(this.rowReqParts);
          this.calTotal();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;

          this.loading = false; //skeleton false
        });
    },
    deleteReqPart: function (rowReqItem, index) {
      this.rowReqParts.splice(index, 1);
      this.calTotal(this.rowReqParts);
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/part-requisition/part/delete", {
          data: {
            partId: rowReqItem.partId,
            reqId: this.reqId,
            reqTotalPrice: this.reqTotalPrice,
            reqDcPer: this.reqDcPer,
            reqDcPrice: this.reqDcPrice,
            reqNetPrice: this.reqNetPrice,
            reqVatPer: this.reqVatPer,
            reqVatPrice: this.reqVatPrice,
            reqGrandTotalPrice: this.reqGrandTotalPrice,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getReqPart(this.reqId);
          this.calTotal();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;

          this.loading = false; //skeleton false
        });
    },
    putSubmit() {
      useNetw
        .put("api/part-requisition/submit", {
          reqId: this.reqId,
        })

        .then((response) => {
          this.calTotal();
          this.getReqPart(this.reqId);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getReqShow(this.reqId);
          this.$router.push({ name: "part-requisition" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    alert(rowReqItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteReqPart(rowReqItem, index);
          }
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันการเบิกหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    handleSearch() {
      this.getRo(this.branchIdRo);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getRo(this.branchIdRo);
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getRo(this.branchIdRo);
    },
  },
  middleware: "authentication",
};
</script>


<style lang="scss" scoped>
.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
</style>

